import axios from 'axios'

export const addCustomerToNewsletter = async ({
    language,
    email,
    name,
    surname,
    telephone,
}) => {
    const newsletterUrl = 'https://api.brevo.com/v3/contacts'

    let formattedTelephone = telephone

    // check telephone and replace first 0 with +49
    if (formattedTelephone) {
        if (formattedTelephone.startsWith('0')) {
            formattedTelephone = '+49' + formattedTelephone.substring(1)
        }
    }

    let newsletterAttributes = {
        WHATSAPP: formattedTelephone,
        SMS: formattedTelephone,
        VORNAME: name,
        NACHNAME: surname,
        KUNDENTYP: 'Privatkunde',
    }

    const data = {
        email: email,
        attributes: newsletterAttributes,
        includeListIds: [3],
        templateId: 1,
        redirectionUrl: `${process.env.GATSBY_SITE_URL}/${language}/NewsletterConfirmed`,
    }

    const config = {
        headers: {
            'api-key': process.env.GATSBY_SENDINBLUE_API_KEY,
            'Content-Type': 'application/json',
            accept: 'application/json',
        },
    }

    try {
        await axios.post(newsletterUrl, data, config)
    } catch (error) {
        // send again if telephone is invalid
        const invalidTelephone =
            error.response.data.message === 'Invalid phone number'

        if (invalidTelephone) {
            newsletterAttributes = {
                WHATSAPP: '',
                SMS: '',
                VORNAME: name,
                NACHNAME: surname,
            }
            const data = {
                email: email,
                attributes: newsletterAttributes,
                includeListIds: [3],
                templateId: 1,
                redirectionUrl: `${process.env.GATSBY_SITE_URL}/${language}/NewsletterConfirmed`,
            }

            try {
                await axios.post(newsletterUrl, data, config)
            } catch (error) {
                console.log('error', error)
            }
        }
    }
}
