exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-de-404-js": () => import("./../../../src/pages/de/404.js" /* webpackChunkName: "component---src-pages-de-404-js" */),
  "component---src-pages-de-contact-js": () => import("./../../../src/pages/de/Contact.js" /* webpackChunkName: "component---src-pages-de-contact-js" */),
  "component---src-pages-de-dummy-js": () => import("./../../../src/pages/de/dummy.js" /* webpackChunkName: "component---src-pages-de-dummy-js" */),
  "component---src-pages-de-events-index-js": () => import("./../../../src/pages/de/Events/index.js" /* webpackChunkName: "component---src-pages-de-events-index-js" */),
  "component---src-pages-de-index-js": () => import("./../../../src/pages/de/index.js" /* webpackChunkName: "component---src-pages-de-index-js" */),
  "component---src-pages-de-legal-imprint-js": () => import("./../../../src/pages/de/Legal/Imprint.js" /* webpackChunkName: "component---src-pages-de-legal-imprint-js" */),
  "component---src-pages-de-legal-payment-methods-js": () => import("./../../../src/pages/de/Legal/Payment-Methods.js" /* webpackChunkName: "component---src-pages-de-legal-payment-methods-js" */),
  "component---src-pages-de-legal-privacy-policy-js": () => import("./../../../src/pages/de/Legal/Privacy-Policy.js" /* webpackChunkName: "component---src-pages-de-legal-privacy-policy-js" */),
  "component---src-pages-de-legal-shipping-js": () => import("./../../../src/pages/de/Legal/Shipping.js" /* webpackChunkName: "component---src-pages-de-legal-shipping-js" */),
  "component---src-pages-de-legal-terms-and-conditions-js": () => import("./../../../src/pages/de/Legal/Terms-And-Conditions.js" /* webpackChunkName: "component---src-pages-de-legal-terms-and-conditions-js" */),
  "component---src-pages-de-legal-withdrawal-js": () => import("./../../../src/pages/de/Legal/Withdrawal.js" /* webpackChunkName: "component---src-pages-de-legal-withdrawal-js" */),
  "component---src-pages-de-media-js": () => import("./../../../src/pages/de/Media.js" /* webpackChunkName: "component---src-pages-de-media-js" */),
  "component---src-pages-de-newsletter-confirmed-js": () => import("./../../../src/pages/de/NewsletterConfirmed.js" /* webpackChunkName: "component---src-pages-de-newsletter-confirmed-js" */),
  "component---src-pages-de-partner-js": () => import("./../../../src/pages/de/Partner.js" /* webpackChunkName: "component---src-pages-de-partner-js" */),
  "component---src-pages-de-private-label-js": () => import("./../../../src/pages/de/Private-Label.js" /* webpackChunkName: "component---src-pages-de-private-label-js" */),
  "component---src-pages-de-shop-checkout-js": () => import("./../../../src/pages/de/Shop/Checkout.js" /* webpackChunkName: "component---src-pages-de-shop-checkout-js" */),
  "component---src-pages-de-shop-index-js": () => import("./../../../src/pages/de/Shop/index.js" /* webpackChunkName: "component---src-pages-de-shop-index-js" */),
  "component---src-pages-de-shop-order-js": () => import("./../../../src/pages/de/Shop/Order.js" /* webpackChunkName: "component---src-pages-de-shop-order-js" */),
  "component---src-pages-de-shop-products-js": () => import("./../../../src/pages/de/Shop/Products.js" /* webpackChunkName: "component---src-pages-de-shop-products-js" */),
  "component---src-pages-de-version-js": () => import("./../../../src/pages/de/Version.js" /* webpackChunkName: "component---src-pages-de-version-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-bundle-template-js": () => import("./../../../src/templates/BundleTemplate.js" /* webpackChunkName: "component---src-templates-bundle-template-js" */),
  "component---src-templates-edition-template-js": () => import("./../../../src/templates/EditionTemplate.js" /* webpackChunkName: "component---src-templates-edition-template-js" */),
  "component---src-templates-news-template-js": () => import("./../../../src/templates/NewsTemplate.js" /* webpackChunkName: "component---src-templates-news-template-js" */),
  "component---src-templates-product-template-js": () => import("./../../../src/templates/ProductTemplate.js" /* webpackChunkName: "component---src-templates-product-template-js" */),
  "component---src-templates-status-template-js": () => import("./../../../src/templates/StatusTemplate.js" /* webpackChunkName: "component---src-templates-status-template-js" */),
  "component---src-templates-veranstaltungs-template-js": () => import("./../../../src/templates/VeranstaltungsTemplate.js" /* webpackChunkName: "component---src-templates-veranstaltungs-template-js" */)
}

