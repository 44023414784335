import { useState, useEffect } from 'react'

function useWindowWidth() {
    // Check if window is defined (so if in the browser or in node.js).
    const isBrowser = typeof window !== 'undefined'
    const [windowWidth, setWindowWidth] = useState(() => {
        if (isBrowser) {
            return window.innerWidth
        } else {
            return 0
        }
    })

    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return windowWidth
}

export default useWindowWidth
