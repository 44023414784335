import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

// ***** Create a new cart in Strapi
export const createStrapiCartID = async () => {
    const uid = await generateUidFromStrapi()
    if (uid) {
        const newUid = createNewStrapiCart(uid)
        return newUid
    } else {
        return null
    }
}

const generateUidFromStrapi = async () => {
    try {
        const response = await axios.get(
            `${process.env.GATSBY_STRAPI_API_URL}/api/carts?fields[0]=id&sort[0]=createdAt:DESC&pagination[start]=0&pagination[limit]=1`,
            {
                headers: {
                    Authorization: `Bearer ${process.env.GATSBY_STRAPI_API_TOKEN}`,
                },
            }
        )
        // handle success
        const uid = uuidv4()
        const newUid = `${uid}-${response.data.data[0].id}`
        return newUid
    } catch (error) {
        return null
    }
}

const createNewStrapiCart = async uid => {
    try {
        await axios.post(
            `${process.env.GATSBY_STRAPI_API_URL}/api/carts`,
            {
                data: {
                    UID: uid,
                },
            },
            {
                headers: {
                    Authorization: `Bearer ${process.env.GATSBY_STRAPI_API_TOKEN}`,
                },
            }
        )
        return uid
    } catch (error) {
        return null
    }
}

// ***** Get a specific product from Strapi
export const getProductFromStrapi = async id => {
    try {
        const response = await axios.get(
            `${process.env.GATSBY_STRAPI_API_URL}/api/produkts/${id}?populate[0]=Vorschaubild&populate[1]=marke&populate[2]=kategorie&populate[3]=edition&populate[4]=Variante&populate[5]=Variante.groesse&populate[6]=Variante.Bildergalerie`,
            {
                headers: {
                    Authorization: `Bearer ${process.env.GATSBY_STRAPI_API_TOKEN}`,
                },
            }
        )
        // handle success
        const product = response?.data?.data
        return product
    } catch (error) {
        return null
    }
}

// ***** Get a specific bundle from Strapi
export const getBundleFromStrapi = async id => {
    try {
        const response = await axios.get(
            `${process.env.GATSBY_STRAPI_API_URL}/api/bundles/${id}?populate[0]=edition&populate[1]=kategorie&populate[2]=marke&populate[3]=Produkt.groesse&populate[4]=Produkt.produkt.edition&populate[5]=Produkt.produkt.Vorschaubild`,
            {
                headers: {
                    Authorization: `Bearer ${process.env.GATSBY_STRAPI_API_TOKEN}`,
                },
            }
        )
        // handle success
        const bundle = response?.data?.data
        return bundle
    } catch (error) {
        return null
    }
}

// ***** Delete a cart in Strapi
export const deleteStrapiCart = async uid => {
    const cart = await getStrapiCart(uid)
    const cart_id = cart.id

    try {
        await axios.delete(
            `${process.env.GATSBY_STRAPI_API_URL}/api/carts/${cart_id}`,
            {
                headers: {
                    Authorization: `Bearer ${process.env.GATSBY_STRAPI_API_TOKEN}`,
                },
            }
        )
        return true
    } catch (error) {
        return false
    }
}

// ***** Get cart from Strapi
export const getStrapiCart = async uid => {
    try {
        const response = await axios.get(
            `${process.env.GATSBY_STRAPI_API_URL}/api/carts?filters[UID][$eq]=${uid}&populate[Cart_Produkte][populate][0]=produkt&populate[Cart_Produkte][populate][1]=groesse&populate[Cart_Bundles][populate][0]=bundle`,
            {
                headers: {
                    Authorization: `Bearer ${process.env.GATSBY_STRAPI_API_TOKEN}`,
                },
            }
        )
        // handle success
        return response.data.data[0]
    } catch (error) {
        return null
    }
}

// ***** Add a product to a cart in Strapi
export const addProductToStrapiCart = async (uid, product, variant, count) => {
    const cart = await getStrapiCart(uid)

    if (cart) {
        const newCart = await updateStrapiCartProduct(
            cart,
            product,
            variant,
            count
        )
        if (newCart) {
            return true
        } else {
            return false
        }
    } else {
        return false
    }
}

const updateStrapiCartProduct = async (cart, product, variant, count) => {
    const cartProducts = cart.attributes.Cart_Produkte || []

    // check if product is allready in cart and update count
    const productInCart = cartProducts.find(
        cartProduct =>
            cartProduct?.produkt?.data?.id === product.strapi_id &&
            cartProduct?.groesse?.data?.id === variant?.groesse?.strapi_id
    )

    if (productInCart) {
        productInCart.Anzahl = productInCart.Anzahl + count
    } else {
        cartProducts.push({
            produkt: product.strapi_id,
            Anzahl: count,
            groesse: {
                id: variant?.groesse?.strapi_id,
            },
        })
    }

    try {
        const response = await axios.put(
            `${process.env.GATSBY_STRAPI_API_URL}/api/carts/${cart.id}`,
            {
                data: {
                    Cart_Produkte: cartProducts,
                },
            },
            {
                headers: {
                    Authorization: `Bearer ${process.env.GATSBY_STRAPI_API_TOKEN}`,
                },
            }
        )
        // handle success
        return response.data
    } catch (error) {
        return null
    }
}

// ***** Increase a product to a cart in Strapi
export const increaseProductToStrapiCart = async (
    uid,
    product_strapi_id,
    variant
) => {
    const cart = await getStrapiCart(uid)

    if (cart) {
        const newCart = await increaseStrapiCart(
            cart,
            product_strapi_id,
            variant
        )
        if (newCart) {
            return true
        } else {
            return false
        }
    } else {
        return false
    }
}

const increaseStrapiCart = async (cart, product_strapi_id, variant) => {
    const cartProducts = cart.attributes.Cart_Produkte || []

    const productInCart = cartProducts.find(
        cartProduct =>
            cartProduct?.produkt?.data?.id === product_strapi_id &&
            cartProduct?.groesse?.data?.attributes?.Name === variant
    )

    if (productInCart) {
        productInCart.Anzahl = productInCart.Anzahl + 1

        try {
            const response = await axios.put(
                `${process.env.GATSBY_STRAPI_API_URL}/api/carts/${cart.id}`,
                {
                    data: {
                        Cart_Produkte: cartProducts,
                    },
                },
                {
                    headers: {
                        Authorization: `Bearer ${process.env.GATSBY_STRAPI_API_TOKEN}`,
                    },
                }
            )
            // handle success
            return response.data
        } catch (error) {
            return null
        }
    } else {
        return false
    }
}

// ***** Decrease a product to a cart in Strapi
export const decreaseProductToStrapiCart = async (
    uid,
    product_strapi_id,
    variant
) => {
    const cart = await getStrapiCart(uid)

    if (cart) {
        const newCart = await decreaseStrapiCartProduct(
            cart,
            product_strapi_id,
            variant
        )
        if (newCart) {
            return true
        } else {
            return false
        }
    } else {
        return false
    }
}

const decreaseStrapiCartProduct = async (cart, product_strapi_id, variant) => {
    const cartProducts = cart.attributes.Cart_Produkte || []

    // check if product is allready in cart and update count
    const productInCart = cartProducts.find(
        cartProduct =>
            cartProduct?.produkt?.data?.id === product_strapi_id &&
            cartProduct?.groesse?.data?.attributes?.Name === variant
    )
    if (productInCart) {
        const futureCount = productInCart.Anzahl - 1

        if (futureCount === 0) {
            const index = cartProducts.indexOf(productInCart)
            if (index > -1) {
                cartProducts.splice(index, 1)
            }
        } else {
            productInCart.Anzahl = productInCart.Anzahl - 1
        }

        try {
            const response = await axios.put(
                `${process.env.GATSBY_STRAPI_API_URL}/api/carts/${cart.id}`,
                {
                    data: {
                        Cart_Produkte: cartProducts,
                    },
                },
                {
                    headers: {
                        Authorization: `Bearer ${process.env.GATSBY_STRAPI_API_TOKEN}`,
                    },
                }
            )
            // handle success
            return response.data
        } catch (error) {
            return null
        }
    } else {
        return false
    }
}

// ***** Remove a product to a cart in Strapi
export const removeProductToStrapiCart = async (
    uid,
    product_strapi_id,
    variant
) => {
    const cart = await getStrapiCart(uid)

    if (cart) {
        const newCart = await removeStrapiCart(cart, product_strapi_id, variant)
        if (newCart) {
            return true
        } else {
            return false
        }
    } else {
        return false
    }
}

const removeStrapiCart = async (cart, product_strapi_id, variant) => {
    const cartProducts = cart.attributes.Cart_Produkte || []

    // check if product is allready in cart and update count
    const productInCart = cartProducts.find(
        cartProduct =>
            cartProduct?.produkt?.data?.id === product_strapi_id &&
            cartProduct?.groesse?.data?.attributes?.Name === variant
    )
    if (productInCart) {
        // find specific product
        const index = cartProducts.indexOf(productInCart)
        if (index > -1) {
            cartProducts.splice(index, 1)
        }

        try {
            const response = await axios.put(
                `${process.env.GATSBY_STRAPI_API_URL}/api/carts/${cart.id}`,
                {
                    data: {
                        Cart_Produkte: cartProducts,
                    },
                },
                {
                    headers: {
                        Authorization: `Bearer ${process.env.GATSBY_STRAPI_API_TOKEN}`,
                    },
                }
            )
            // handle success
            return response.data
        } catch (error) {
            return null
        }
    } else {
        return false
    }
}

// ***** Add a bundle to a cart in Strapi
export const addBundleToStrapiCart = async (
    uid,
    bundle,
    productOne,
    productTwo,
    count
) => {
    const cart = await getStrapiCart(uid)

    if (cart) {
        const newData = await updateStrapiCartBundle(
            cart,
            bundle,
            productOne,
            productTwo,
            count
        )
        if (newData) {
            return true
        } else {
            return false
        }
    } else {
        return false
    }
}

const updateStrapiCartBundle = async (
    cart,
    bundle,
    productOne,
    productTwo,
    count
) => {
    const cartBundles = cart.attributes.Cart_Bundles || []

    // check if bundle is allready in cart and update count
    const bundleInCart = cartBundles.find(
        cartBundle =>
            cartBundle?.bundle?.data?.id === bundle.strapi_id &&
            cartBundle?.Produkt_1 === productOne &&
            cartBundle?.Produkt_2 === productTwo
    )

    if (bundleInCart) {
        bundleInCart.Anzahl = bundleInCart.Anzahl + count
    } else {
        cartBundles.push({
            bundle: bundle.strapi_id,
            Anzahl: count,
            Produkt_1: productOne,
            Produkt_2: productTwo,
        })
    }

    try {
        const response = await axios.put(
            `${process.env.GATSBY_STRAPI_API_URL}/api/carts/${cart.id}`,
            {
                data: {
                    Cart_Bundles: cartBundles,
                },
            },
            {
                headers: {
                    Authorization: `Bearer ${process.env.GATSBY_STRAPI_API_TOKEN}`,
                },
            }
        )
        // handle success
        return response.data
    } catch (error) {
        return null
    }
}

// ***** Increase a bundle to a cart in Strapi
export const increaseBundleToStrapiCart = async (
    uid,
    bundle_strapi_id,
    productOne,
    productTwo
) => {
    const cart = await getStrapiCart(uid)

    if (cart) {
        const newCart = await increaseStrapiCartBundle(
            cart,
            bundle_strapi_id,
            productOne,
            productTwo
        )
        if (newCart) {
            return true
        } else {
            return false
        }
    } else {
        return false
    }
}

const increaseStrapiCartBundle = async (
    cart,
    bundle_strapi_id,
    productOne,
    productTwo
) => {
    const cartBundles = cart.attributes.Cart_Bundles || []

    // check if bundle is allready in cart and update count
    const bundleInCart = cartBundles.find(
        cartBundle =>
            cartBundle?.bundle?.data?.id === bundle_strapi_id &&
            cartBundle?.Produkt_1 === productOne &&
            cartBundle?.Produkt_2 === productTwo
    )

    if (bundleInCart) {
        bundleInCart.Anzahl = bundleInCart.Anzahl + 1

        try {
            const response = await axios.put(
                `${process.env.GATSBY_STRAPI_API_URL}/api/carts/${cart.id}`,
                {
                    data: {
                        Cart_Bundles: cartBundles,
                    },
                },
                {
                    headers: {
                        Authorization: `Bearer ${process.env.GATSBY_STRAPI_API_TOKEN}`,
                    },
                }
            )
            // handle success
            return response.data
        } catch (error) {
            return null
        }
    } else {
        return false
    }
}

// ***** Decrease a bundle to a cart in Strapi
export const decreaseBundleToStrapiCart = async (
    uid,
    bundle_strapi_id,
    productOne,
    productTwo
) => {
    const cart = await getStrapiCart(uid)

    if (cart) {
        const newCart = await decreaseStrapiCartBundle(
            cart,
            bundle_strapi_id,
            productOne,
            productTwo
        )
        if (newCart) {
            return true
        } else {
            return false
        }
    } else {
        return false
    }
}

const decreaseStrapiCartBundle = async (
    cart,
    bundle_strapi_id,
    productOne,
    productTwo
) => {
    const cartBundles = cart.attributes.Cart_Bundles || []

    // check if bundle is allready in cart and update count
    const bundleInCart = cartBundles.find(
        cartBundle =>
            cartBundle?.bundle?.data?.id === bundle_strapi_id &&
            cartBundle?.Produkt_1 === productOne &&
            cartBundle?.Produkt_2 === productTwo
    )

    if (bundleInCart) {
        const futureCount = bundleInCart.Anzahl - 1

        if (futureCount === 0) {
            const index = cartBundles.indexOf(bundleInCart)
            if (index > -1) {
                cartBundles.splice(index, 1)
            }
        } else {
            bundleInCart.Anzahl = bundleInCart.Anzahl - 1
        }

        try {
            const response = await axios.put(
                `${process.env.GATSBY_STRAPI_API_URL}/api/carts/${cart.id}`,
                {
                    data: {
                        Cart_Bundles: cartBundles,
                    },
                },
                {
                    headers: {
                        Authorization: `Bearer ${process.env.GATSBY_STRAPI_API_TOKEN}`,
                    },
                }
            )
            // handle success
            return response.data
        } catch (error) {
            return null
        }
    } else {
        return false
    }
}

// ***** Remove a bundle to a cart in Strapi
export const removeBundleToStrapiCart = async (
    uid,
    bundle_strapi_id,
    productOne,
    productTwo
) => {
    const cart = await getStrapiCart(uid)

    if (cart) {
        const newCart = await removeStrapiCartBundle(
            cart,
            bundle_strapi_id,
            productOne,
            productTwo
        )
        if (newCart) {
            return true
        } else {
            return false
        }
    } else {
        return false
    }
}

const removeStrapiCartBundle = async (
    cart,
    bundle_strapi_id,
    productOne,
    productTwo
) => {
    const cartBundles = cart.attributes.Cart_Bundles || []

    // check if bundle is allready in cart and update count
    const bundleInCart = cartBundles.find(
        cartBundle =>
            cartBundle?.bundle?.data?.id === bundle_strapi_id &&
            cartBundle?.Produkt_1 === productOne &&
            cartBundle?.Produkt_2 === productTwo
    )

    if (bundleInCart) {
        const index = cartBundles.indexOf(bundleInCart)
        if (index > -1) {
            cartBundles.splice(index, 1)
        }

        try {
            const response = await axios.put(
                `${process.env.GATSBY_STRAPI_API_URL}/api/carts/${cart.id}`,
                {
                    data: {
                        Cart_Bundles: cartBundles,
                    },
                },
                {
                    headers: {
                        Authorization: `Bearer ${process.env.GATSBY_STRAPI_API_TOKEN}`,
                    },
                }
            )
            // handle success
            return response.data
        } catch (error) {
            return null
        }
    } else {
        return false
    }
}
