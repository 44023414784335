// * Fonts
// Lora
import '@fontsource/lora/400.css'
import '@fontsource/lora/500.css'
import '@fontsource/lora/600.css'
import '@fontsource/lora/700.css'
// Raleway
import '@fontsource/raleway/100.css'
import '@fontsource/raleway/200.css'
import '@fontsource/raleway/300.css'
import '@fontsource/raleway/400.css'
import '@fontsource/raleway/500.css'
import '@fontsource/raleway/600.css'
import '@fontsource/raleway/700.css'
import '@fontsource/raleway/800.css'
import '@fontsource/raleway/900.css'

// normalize CSS across browsers
import './src/assets/css/normalize.css'
// custom CSS styles
import './src/assets/css/style.css'
import './src/assets/css/animations.css'

// App Context
import React from 'react'
import RootLayout from './src/components/Layouts/RootLayout'

export const wrapRootElement = ({ element }) => {
    return <RootLayout>{element}</RootLayout>
}
