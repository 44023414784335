import ReactGA from 'react-ga4'

const TrackViewProductEvent = item => {
    ReactGA.event({
        action: 'view_item',
        category: 'Ecommerce',
        label: item.name + ' angesehen',
        value: item.price,
        currency: 'EUR',
        nonInteraction: false, // da dies eine interaktive Aktion ist
        items: [
            {
                item_id: item.id, // Artikel-ID
                item_name: item.name, // Artikelname
                item_category: item.category, // Kategorie des Artikels
                item_variant: item.variant, // Variante des Artikels
                item_brand: item.brand, // Marke des Artikels
                price: item.price, // Preis pro Artikel
                quantity: 1, // Menge
            },
        ],
    })
}

const TrackAddProductToCartEvent = (item, quantity) => {
    const price = item.price // Preis des Artikels
    const totalValue = price * quantity // Gesamtwert basierend auf Preis und Menge

    ReactGA.event({
        action: 'add_to_cart',
        category: 'Ecommerce',
        label: item.name + ' zum Warenkorb hinzugefügt',
        value: totalValue,
        currency: 'EUR',
        nonInteraction: false, // da dies eine interaktive Aktion ist
        items: [
            {
                item_id: item.id, // Artikel-ID
                item_name: item.name, // Artikelname
                item_category: item.category, // Kategorie des Artikels
                item_variant: item.variant, // Variante des Artikels
                item_brand: item.brand, // Marke des Artikels
                price: item.price, // Preis pro Artikel
                quantity: quantity, // Menge
            },
        ],
    })
}

const TrackBeginCheckoutEvent = (price, items) => {
    ReactGA.event({
        action: 'begin_checkout',
        category: 'Ecommerce',
        label: 'Zur Kasse gegangen',
        value: price,
        currency: 'EUR',
        nonInteraction: false, // da dies eine interaktive Aktion ist
        items: items,
    })
}

const TrackPurchaseEvent = (price, items) => {
    ReactGA.event({
        action: 'purchase',
        category: 'Ecommerce',
        label: 'Kauf abgeschlossen',
        value: price,
        currency: 'EUR',
        nonInteraction: false, // da dies eine interaktive Aktion ist
        items: items,
    })
}

export {
    TrackViewProductEvent,
    TrackAddProductToCartEvent,
    TrackBeginCheckoutEvent,
    TrackPurchaseEvent,
}
